// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Link from "../../../styleguide/components/Link/Link.res.js";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Popover from "../../../styleguide/components/Popover/Popover.res.js";
import * as IconSelect from "../../../styleguide/icons/IconSelect.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_Resource from "../../../routes/common/Routes_Resource.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ResourcesOptionsScss from "./ResourcesOptions.scss";

var css = ResourcesOptionsScss;

function ResourcesOptions(props) {
  return JsxRuntime.jsxs(Popover.make, {
              children: [
                JsxRuntime.jsx(Popover.Trigger.make, {
                      children: JsxRuntime.jsx(Button.make, {
                            size: "SM",
                            color: "Primary",
                            className: css.ctaButton,
                            children: "Options",
                            iconSuffixComponent: Caml_option.some(JsxRuntime.jsx(IconSelect.make, {
                                      size: "LG",
                                      color: "White"
                                    }))
                          })
                    }),
                JsxRuntime.jsxs(Popover.Body.make, {
                      position: {
                        TAG: "Below",
                        _0: "RightEdge"
                      },
                      className: css.popoverBody,
                      children: [
                        JsxRuntime.jsxs(Link.make, {
                              href: Routes_Resource.Dashboard.$$new,
                              className: css.popoverBodyLink,
                              children: [
                                "New Resource",
                                JsxRuntime.jsx(IconSelect.make, {
                                      size: "MD",
                                      color: "LightestGray",
                                      className: css.popoverBodyLinkIcon
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(Link.make, {
                              href: Routes_Resource.Dashboard.index + ".csv",
                              className: css.popoverBodyLink,
                              children: [
                                "Download CSV",
                                JsxRuntime.jsx(IconSelect.make, {
                                      size: "MD",
                                      color: "LightestGray",
                                      className: css.popoverBodyLinkIcon
                                    })
                              ]
                            })
                      ]
                    })
              ]
            });
}

var make = ResourcesOptions;

export {
  css ,
  make ,
}
/* css Not a pure module */
