// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Control from "../../components/Control/Control.res.js";
import * as ToggleScss from "./Toggle.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = ToggleScss;

function Toggle(props) {
  var onChange = props.onChange;
  var __disabled = props.disabled;
  var __id = props.id;
  var id = __id !== undefined ? __id : "";
  var disabled = __disabled !== undefined ? __disabled : false;
  var tmp;
  switch (props.size) {
    case "MD" :
        tmp = css.md;
        break;
    case "LG" :
        tmp = css.lg;
        break;
    case "SM" :
        tmp = css.sm;
        break;
    
  }
  return JsxRuntime.jsx(Control.make, {
              id: id,
              className: css.control,
              onClick: (function (param) {
                  if (!disabled) {
                    return onChange();
                  }
                  
                }),
              disabled: disabled,
              children: JsxRuntime.jsx("div", {
                    className: Cx.cx([
                          css.toggle,
                          disabled ? css.disabled : "",
                          props.on ? css.on : css.off,
                          tmp
                        ])
                  })
            });
}

var make = Toggle;

export {
  css ,
  make ,
}
/* css Not a pure module */
