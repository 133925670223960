// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Link from "../../../styleguide/components/Link/Link.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_Resource from "../../../routes/common/Routes_Resource.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_ResourceCategory from "../../../routes/common/Routes_ResourceCategory.res.js";
import * as ResourceDashboardNavbarScss from "./ResourceDashboardNavbar.scss";

var css = ResourceDashboardNavbarScss;

function ResourceDashboardNavbar(props) {
  var selectedCategory = props.selectedCategory;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: Belt_Array.mapWithIndex([
                          {
                            id: "Categories",
                            slug: Routes_ResourceCategory.dashboard
                          },
                          {
                            id: "Resources",
                            slug: Routes_Resource.Dashboard.index
                          },
                          {
                            id: "Analytics",
                            slug: Routes_Resource.Dashboard.analytics
                          }
                        ], (function (index, category) {
                            var match = category.id;
                            return JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(Link.make, {
                                              href: category.slug,
                                              className: css.linkItem,
                                              children: match === "Categories" ? "Resource Categories" : (
                                                  match === "Analytics" ? "Analytics" : "Resources"
                                                )
                                            }),
                                        className: Cx.cx([
                                              css.linkContainer,
                                              category.id === selectedCategory ? css.underlinedLink : ""
                                            ])
                                      }, String(index));
                          })),
                    className: css.barContainer
                  }),
              className: css.barWrapper
            });
}

var make = ResourceDashboardNavbar;

export {
  css ,
  make ,
}
/* css Not a pure module */
